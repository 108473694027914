/* eslint-disable */
import axios from "axios";
import jsCookie from "js-cookie";
import moment from "moment";


let tokenMap = {
  PriceHistory: "ac77b27423b12a9d02f5361bce691c4f3a7ea62121ae736abd7d301a737cf831",
  DetailedQuoteTabChart: "2ec0dbf4b5513b88169a3d3e1962f00682447abc364ac555cf898d6c3dbb1b2c",
  FullNews: "79c0eaf32c01382d1c21be0e01dc083d43a3431f28b1d692606300939fd6a71c",
  QuoteHead: "a900a06cc6b3e8036afb9eeb1bbf9783f0007698ed8f5cb1e373dc790e7be2e5",
  CompanyProfile: "1e1d7cb1de1fd2fe52684abdea41a446919a5fe12776dfab88615ac1ce1ec2f6",
  InteractiveChart: "43d2efa1bc5c4d25786ecdab48b3a9dfd47827e39a4748725fe46e1f3ee38a74",
  MarketIndices: "01d20e0f456c3e8cf59b62e3fef414273a3e5577a6bfd24dd194644a94e8ee51",
  Watchlist: "eff2ec588be1b85759993b66f24e699b078c449bf9a410243bcac53d841e75f1",
  MarketDiary: "d116063137c72e55e4d6ea3815be1ab7c2f2574f11273790db75a6f75a0bedc1",
  KeyRatios: "f5cd1d0665b4a31e91b8dd015b690a768da689dc443580ddbf0c473515819d28",
  MiniQuotes: "c2d50450cd3c73f82ada00560519c39699666b115a35cc4cf20c9c25a17e386c",
  SectorPerformance: "87962df5c464e05aa8a7400da480b4443bae2eeef3c40bbaad93653f19fa1d26",
  EarningsCalendar: "13d7374fc24d01ac40457f0d528c457509e1e078fdf38f44dc4b3ad997e6ad7c",
  ScrollingTicker: "da95078b9160e93b87261d83fb4102da6239274418263bfcfe879b2f5629be99",
  IndexConstituents: "c1a0dbaafedf9be82c91bb344eec9a6d82b45a42460345354397a99a4ded281b",
  MarketCurrencies: "0911be9a1ff4ada6c27f71a316734219cf09346161b6faa2623c4d47badb68e1",
  MarketCurrenciesTabbed: "2f9f24cca5e30ef7f0945229c7077dd605b183b812ace888d1ba5c029e1d924b",
  FundOverview: "690d5886762bfad93a2373f3f8618d587239631036935c526b4794eb77b8d3ad",
  FundPerformance: "fd7bc28b2c7908524c44f9d54d790fea2081e4b61599fb4e9fe04541d1f68b19",
  IndustryPeersComparison: "8a394f09cb8540c8be8988780660a7ae5b583c331a1f6cb12834f051a0169a8f",
  Trades: "8d13393cf84e061ca62355ffe113f006597b9f59bd77a69dda38eb5be41b71e2",
  MarketScreener: '5bce4b128f9078f167181cd08afb1d38aa34941e20629b8df36f902dfcf13fa0',
  EquityScreener: "3f2b7b336a32dd4bb9db717c0d1d5710f5f83d71c2500c03225ef17402affaec",
  AdvancedChart: "5ad521e05faf5778d567f6d0012ec34d6cdbaeb2462f41568f66558bc7b4ced9"
};

/**
 * @mixin
 */
export default {
  /**
   *
   *
   * @param {any} Vue
   * @param {any} opts
   * @returns
   */
  install: (Vue, qStore) => {

    Vue.prototype.$qModCore = {
      /**
       *
       * @param {*} qmodTool
       */
      validateTool : async function (qmodTool, qmWMID, qmENV) {
        let qmSID = qStore.state.SID;
        let extra = qmSID ? '?sid='+qmSID : ''
        qmWMID = qStore.state.WMID;
        qmENV = qStore.state.ENV;
        let qToken = tokenMap[qmodTool];
        let qCookieName = `${qmENV}_${qmWMID}_${qmodTool}`;
        let qTokenCookie = jsCookie.get(qCookieName);
        if (qTokenCookie) return qTokenCookie;
        try {
          const response = await axios.get(
            `//${qmENV}.quotemedia.com/auth/g/authenticate/dataTool/v0/${qmWMID}/${qToken}${extra}`
          );
          // console.log(response.data)
          jsCookie.set(qCookieName, response.data.token, {
            expires: new Date(new Date().getTime() + 30 * 60 * 1000)
          });
          return response.data.token;
        } catch (e) {
          // console.log(e);
          return "INVALID";
        }
      },
      async HC() {
        const Highcharts = () =>
          import( /* webpackChunkName: "v_hc" */ "highcharts/highstock");
        return await Highcharts();
      },
      async qmAxiosGet(qmodTool, URL) {
        let qToken = await this.validateTool(qmodTool)
        let separator = (URL.endsWith('?')) ? '' : '&'
        return await axios.get(`${URL}${separator}token=${qToken}`)
      },
      axios,
      jsCookie,
      moment
    };
    Vue.mixin({
      methods: {
        auto(value, type) {
          if (type == "date") {
            return moment(String(value)).format("YYYY-MM-DD");
          } else if (type == "text") {
            return value;
          } else if (type == "ptext") {
            return '(' + value + ')'
          } else if (type == "percent") {
            return this.autoNumber(value) + '%';
          } else if (type == "tick") {
            if (value > 0) {
              return "<span class='fa fa-arrow-circle-up qmod-c-up'></span>"
            } else if (value < 0) {
              return "<span class='fa fa-arrow-circle-down qmod-c-down'></span>"
            } else {
              return ''
            }
          } else {
            if (typeof value === 'string')
              return value;
            return this.autoNumber(value);
          }
        },
        autoNumber(value) {
          if (
            typeof value == "undefined" ||
            isNaN(value) ||
            !isFinite(value) ||
            value == null
          ) {
            return "--"; // bad input
          }
          if (String(value).indexOf(".") == -1) {
            // not a decimal
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(2).replace(/\.0$/, "") + "B";
            }
            if (value >= 1000000) {
              return (value / 1000000).toFixed(2).replace(/\.0$/, "") + "M";
            }
            if (value >= 1000) {
              return (value / 1000).toFixed(2).replace(/\.0$/, "") + "K";
            }
            return value.toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
              useGrouping: true
            });
          }

          //let qValue = parseFloat(Math.abs(value)); //remove +/- format as float --why tho? - lp
          let qValue = parseFloat(value);

          return qValue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 5,
            useGrouping: true
          });
        },
        changeIcon: function (val) {
          if (Number(val) > 0) {
            return 'fa fa-arrow-circle-up qmod-c-up'
          } else if (Number(val) < 0) {
            return 'fa fa-arrow-circle-down qmod-c-down'
          }
        },
        changeClass: function (val) {
          if (Number(val) > 0) {
            return 'qmod-c-up'
          } else if (Number(val) < 0) {
            return 'qmod-c-down'
          }
        }
      },
      filters: {
        asTime(dateTime) {
          if (!dateTime) return "";
          return moment(String(dateTime.split("-05:00")[0])).format("h:mm A");
        },
        asDate(dateTime) {
          if (!dateTime) return "";
          return moment(String(dateTime)).format("ddd MMM Do, YYYY");
        },
        asDateTime(dateTime) {
          if (!dateTime) return "";
          return moment(String(dateTime.split("-05:00")[0])).format(
            "ddd MMM Do, YYYY h:mm A"
          );
        },
        asEnt: function (ent) {
          let qmEnts = {
            RT: "Real-Time Price",
            RTN: "Real-Time NASDAQ Price (not sourced from all markets)",
            RTB: "Real-Time BATS BZX Price (not sourced from all markets)",
            DL: "Delayed Price",
            EOD: "End-of-Day Price",
            PD: "Previous Day Price",
            PW: "Previous Week Price"
          };
          return qmEnts[ent];
        },
        asDec: function (num) {
          return parseFloat(num).toFixed(2);
        },
        asNumber: function (num) {
          if (num >= 1000000000) {
            return (num / 1000000000).toFixed(2).replace(/\.0$/, "") + "B";
          }
          if (num >= 1000000) {
            return (num / 1000000).toFixed(2).replace(/\.0$/, "") + "M";
          }
          if (num >= 1000) {
            return (num / 1000).toFixed(2).replace(/\.0$/, "") + "K";
          }
          return num;
        }
      }
    });
  }
};