// const messages = {
//   en: {
//     message: {
//       hello: "hello world",
//     },
//     ETF: "ETF",
//     Equity: "Equity",
//     Index: "Index",
//     labels: {
//       ohlcmsg: "This indicator requires the chart type to be either Candlestick or OHLC",
//       overlay: 'Overlay Indicators',
//       lower: 'Lower Indicators',
//       active: 'Active Indicators',
//       index: 'index',
//       limitedresults: "Screener results are limited to top 1000 based on Market Cap.",
//       showing: "Showing",
//       of: "of",
//       select: "select",
//       min: "min",
//       max: "max",
//       matches: "matches",
//       delete: "delete",
//       pricedata: "Price Data",
//       fundamentals: "Fundamentals",
//       week52: "52 Week",

//       open: "Open",
//       high: "High",
//       low: "Low",

//       last: "last",
//       frequency: "Frequency",
//       events: "Events",
//       scale: "Scale",
//       type: "Type",
//       "1D": "1D",
//       "5D": "5D",
//       "3M": "3M",
//       "6M": "6M",
//       "1Y": "1Y",
//       "3Y": "3Y",
//       "2Y": "2Y",
//       "5Y": "5Y",
//       "10Y": "10Y",
//       "20Y": "20Y",
//       "30Y": "30Y",
//       "YTD": "YTD",

//       "1min": "1 Min",
//       "2min": "2 Min",
//       "5min": "5 Min",
//       "10min": "10 Min",
//       "60min": "60 Min",

//       daily: "Daily",
//       weekly: "Weekly",
//       monthly: "Monthly",

//       adjustments: "Adjustments",
//       splits_and_divs: "Splits & Divs",
//       splits: "Splits",
//       unadjusted: "Unadjusted",

//       price: "Price",
//       change_dollar: "Change $",
//       change_percent: "Change %",

//       period: "Period",
//       sma: "SMA",
//       ema: "EMA",
//       vwap: "VWAP",

//       pivotpoints: 'Pivot Points',
//       priceenvelopes : 'Price Envelopes',
//       parabolic_sar: 'Parabolic SAR',
//       volume_by_price: 'Volume by Price',
//       zigzag: 'Zig Zag',
//       detrended_price: 'Detrended Price',

//       earnings: "Earnings",
//       dividends: "Dividends",

//       area: "Area",
//       line: "Line",
//       "OHLC+": "OHLC+",
//       OHLC: "OHLC",
//       "Candlestick+": "Candlestick+",
//       Candlestick: "Candlestick",

//       symbol_lookup: "Symbol Lookup",
//       symbol_or_name: "Symbol or Name",
//       search_by: "Search by",

//       close: "Close",
//       name: "Name",
//       symbol: "Symbol",

//       add_indicator: "Add Indicator",
//       indicators: "Indicators",

//       exponential_moving_average: "Exponential Moving Average",
//       volume_weighted_average_price: "Volume Weighted Average Price",
//       simple_moving_average: "Simple Moving Average",
//       moving_average_convergence_divergence: "Moving Average Convergence Divergence",
//       bollinger_bands: "Bollinger Bands",
//       relative_strength_index: "Relative Strength Index",
//       average_true_range: "Average True Range",
//       rate_of_change: "Rate of Change",
//       weighted_moving_average: "Weighted Moving Average",
//       commodity_channel_index: "Commodity Channel Index",
//       momentum: "Momentum",

//       base_on: "Base on",
//       color: "Colour",
//       standard_deviation: "Standard Deviation",
//       short_period: "Short Period",
//       long_period: "Long Period",
//       signal_period: "Signal Period",

//       shortPeriod: "Short Period",
//       longPeriod: "Long Period",
//       signalPeriod: "Signal Period",

//       loading_dataset: "Loading Dataset: ",
//       add: 'ADD',
//       remove: 'REMOVE',
//       ranges: 'ranges',
//       increment: 'increment',
//       Initial_Acceleration_Factor: 'Initial Acceleration Factor',
//       Max_Acceleration_Factor: 'Max Acceleration Factor',
//       Deviation: 'Deviation',
//       High_Index: 'High Index',
//       Low_Index: 'Low Index',
//       Bottom_Band: 'Bottom Band',
//       Top_Band: 'Top Band',

//       deviation: 'Deviation',

//       highIndex: 'High Index',
//       lowIndex: 'Low Index',
//       bottomBand: 'Bottom Band',
//       topBand: 'Top Band',
//       save_chart: 'Save Chart',

//     },
//     screener: {
//       searchforcriteria: 'Search for criteria',
//       presetscreener: "Preset Screens", //Écrans pré-établis
//       critera: "Criteria",
//       addfilter: "Add Filter",
//       addfilters: "Add Filter(s)",
//       marketscreener: "Market Screener",
//       nocritera: "You haven't chosen any criteria yet. Please select a criteria to begin",
//       noresults: "No results found for selected criteria",
//       save: 'Save',
//       savedscreens: 'Saved Screens',
//       nosaved: 'You have no saved screens',
//       nosavedcol: 'You have no saved Columns',
//       entername: 'Enter name for Screen',
//       customCols: "Manage Views",
//       entercol: 'Enter name for new view',
//       modify_existing: "Modify Existing",
//       or: 'OR',
//       updateexisting: 'Update Existing Screens',
//       showcritera: 'Show Criteria',
//       hidecritera: 'Hide Criteria',
//       "saved": "Saved",
//       "new": "New"
//     },
//     tabs: {
//       overview: 'overview',
//       basics: "basics",
//       ratios: "ratios",
//       performance: "performance",
//       dividends: "dividends",
//       profitability: "profitability",
//       trading: "trading",
//       financials:"financials",
//       export: "export",
//       manage: "manage",
//       portfolio: 'portfolio',
//       rating: 'rating/risk',
//       holdings: 'holdings',
//       pricetrading: 'price/trading',
//       purchaseinfo: 'purchase info'
//     },
//     tableheaders: {
//       symbol: "Symbol",
//       name: "Company",
//       last: "Price ($)",
//       change: "Chg ($)",
//       changepct: "Chg (%)",
//       open: "Open ($)",
//       high: "high ($)",
//       low: "low ($)",
//       bid: "bid ($)",
//       ask: "ask ($)",
//       vwap: "vwap",
//       volume: "vol.",

//       avgCreditQuality: "avg. credit quality",
//       inverseFund: "inverse fund",
//       leveragedFund: "leveraged fund",

//       divyeild: "div yield (%)",
//       beta: "beta",
//       mktcap: "mkt cap",
//       sharesout: "shares out",
//       eps: "eps",
//       peratio: "P/E",
//       pbratio: "P/B",
//       high_vwap: "High VWAP",
//       low_vwap: "Low VWAP",
//       wkh52: "High",
//       wklw52: "Low",
//       high_vol: "High Vol.",
//       low_vol: "Low Vol.",
//       avg_vol: "Avg. Vol.",

//       marketCapitalization: "market Cap.",
//       divYield: "div Yield",
//       revenueGrowth3yr: "rev. Growth 3yr",
//       incomeGrowth3yr: "inc. Growth 3yr",

//       Exchange: "Exchange",
//       Sector: "Sector",
//       Industry: "Industry",
//       revenueLtm: "Rev (LTM)",
//       freeCashFlow: "Free Cash Flow",
//       employees: "employees",
//       totalRevenue: "Total Rev.",
//       stockPrice: "Price",

//       latestFiscalEps: "EPS",
//       pbRatio: "PB Ratio",
//       peRatio: "PE Ratio",
//       currentRatio: "current Ratio",
//       quickRatio: "quick Ratio",
//       debtCapitalRatio: "debt Cap. Ratio",
//       bvps: "BVPS",
//       pcfRatio: "% Ratio",
//       debtToEquityRatio: "debt Equity Ratio",

//       priceChange: "price chg.",
//       change52w: "chg. 52w",
//       pricePerfomance7d: "Perf. 7d",
//       pricePerfomance30d: "Perf. 30d",
//       pricePerfomance90d: "Perf. 90d",
//       pricePerfomanceYtd: "Perf. Ytd",
//       incomeGrowth3yr: "inc. Growth 3yr",
//       incomeGrowth5yr: "inc. Growth 5yr",
//       revenueGrowth3yr: "rev. Growth 3yr",
//       revenueGrowth5yr: "rev. Growth 5yr",

//       divYield: "div Yield",
//       divRate: "div rate",
//       divGrowthAvg3y: "div Growth Avg 3y",
//       divGrowthAvg5y: "div Growt Avg 5y",

//       roa: "ROA",
//       roe: "ROE",
//       roc: "ROC",
//       grossMargin: "gross Margin",
//       ebitdaMargin: "EBITDA Margin",
//       ebitMargin: "EBIT Margin",
//       revPs: "rev. Ps",

//       r2: "r2",
//       alpha: "alpha",
//       beta: "beta",
//       stddev: "stddev",
//       instHoldingPct: "inst %",
//       insiderHoldingPct: "insider %",
//       yesterdaysVolume: "Yest. Vol.",
//       averageVolume10d: "Avg. Vol. 10d",
//       averageVolume30d: "Avg. Vol. 30d",
//       averageVolume50d: "Avg. Vol. 50d",
//       averageVolume90d: "Avg. Vol. 90d",

//       currentLiabilities: "current Liabilities",
//       totalEquity: "total Equity",
//       bookValue: "book Value",
//       currentAssets: "current Assets",
//       totalAssets: "total Assets",

//       familyNames: 'fund name',
//       categoryNames: 'category name',
//       instrumentType: 'instrument Type',
//       investmentStyle: 'investment Style',
//       avgCreditRating: 'avg Credit Rating',
//       indexFund: 'index Fund',
//       mer: 'mer',

//       inceptionDate: 'inception Date',
//       turnover: 'turnover',
//       netAssets: 'net Assets',
//       managerTenure: 'Tenure',
//       assetsUnderMgmt: 'assets Under Mgmt',

//       beta: 'beta',
//       stdDeviation: 'standard dev',
//       alpha: 'alpha',
//       sharpeRatio: 'sharpe Ratio',
//       rsquared: 'r squared',
//       treynor: 'treynor ratio',
//       sortino: 'sortino ratio',
//       informationRatio: 'information Ratio',
//       captureRatioUp: 'captur Ratio Up',
//       captureRatioDown: 'capture Ratio Down',

//       navPerformance1Day: '1 Day',
//       navPerformance1Month: '1 Month',
//       navPerformance3Month: '3 Month',
//       navPerformanceYTD: 'YTD',
//       navPerformance1Year: '1 Year',
//       navPerformance3Year: '3 Year',
//       navPerformance5Year: '5 Year',
//       navPerformance10Year: '10 Year',
//       error: 'nav error',

//       dividendFrequency: 'dividend Frequency',
//       distributionYield: 'distribution Yield',

//       topSector: 'top Sector',
//       topRegionName: 'top Region Name',
//       holding1: 'holding 1',
//       holding2: 'holding 2',
//       holding3: 'holding 3',
//       holding4: 'holding 4',
//       holding5: 'holding 5',
//       holding6: 'holding 6',
//       holding7: 'holding 7',
//       holding8: 'holding 8',
//       holding9: 'holding 9',
//       holding10: 'holding 10',

//       '52weekabove': '52week above',
//       '52weekbelow': '52week below',
//       nav: 'nav',
//       navChange: 'change',

//       fundSaleStatus: 'fund Sale Status',
//       rrspEligibility: 'RRSP Eligibility',
//       minimumInitialInvestment: 'min Initial Investment',
//       minimumSubsequentInvestment: 'min Subsequent Investment',
//       rrspMinInvestment: 'Min RRSP Investment',

//     },
//     msgs: {
//       to_get_started_type_in_the_input_above: "To get started, type in the input above.",
//       min_to_low: 'Value entered is lower than Minimum.  Displaying Minimum.',
//       max_to_low: 'Value entered is higher than Maximum.  Displaying Maximum.',
//     },
//     buttons: {
//       close: "Close",
//       add_symbol: "Add Symbol",
//       indicators: "Indicators",
//     },
//   },
//   fr: {
//     ETF: "FNB",
//     Equity: "Action",
//     Index: "Indice",
//     labels: {
//       ohlcmsg: "Cet indicateur nécessite que le type de graphique soit Chandelier ou OHLC",
//       overlay: 'Indicateurs de superposition',
//       lower: 'Indicateurs inférieurs',
//       active: 'Indicateurs actifs',
//       index: 'index',
//       add: 'AJOUTER',
//       "remove": "RETIRER",
//       showing: "Affichage de",
//       of: "sur",
//       limitedresults: "Les résultats de la sélection sont limités aux 1000 premiers en fonction de la cap boursière.",
//       select: "choisir",
//       min: "Min",
//       max: "Max",
//       matches: "égaler",
//       delete: "effacer",
//       pricedata: "Cote",
//       fundamentals: "Fondamentaux",
//       week52: "52 sem.",
//       open: "Ouv",
//       high: "Haut",
//       low: "Bas",
//       last: "Dern",
//       frequency: "Fréquence",
//       events: "Événements",
//       scale: "Échelle",
//       type: "Catégorie",
//       "1D": "1J",
//       "5D": "5J",
//       "3M": "3M",
//       "6M": "6M",
//       "1Y": "1A",
//       "2Y": "2A",
//       "3Y": "3A",
//       "5Y": "5A",
//       "10Y": "10A",
//       "20Y": "20A",
//       "30Y": "30A",

//       "1min": "1 Min",
//       "2min": "2 Min",
//       "5min": "5 Min",
//       "10min": "10 Min",
//       "60min": "60 Min",
//       "YTD": "AJP",

//       daily: "Quotidien",
//       weekly: "Hebdomadaire",
//       monthly: "Mensuel",

//       adjustments: "Ajustement",
//       splits_and_divs: "Fractionnements & Divs",
//       splits: "Fractionnements",
//       unadjusted: "Non ajusté",

//       price: "Prix",
//       change_dollar: "Variation $",
//       change_percent: "Variation %",

//       earnings: "Résultats",
//       dividends: "Dividendes",

//       area: "Aires",
//       line: "Ligne",
//       "OHLC+": "OHBF+",
//       OHLC: "OHBF",
//       "Candlestick+": "Chandelier+",
//       Candlestick: "Chandelier",

//       symbol_lookup: "Recherche de symbole",
//       symbol_or_name: "Symbole ou nom",
//       search_by: "Recherché par",

//       close: "Fermer",
//       name: "Nom",
//       symbol: "Symbole",

//       add_indicator: "Ajouter un indicateur",
//       indicators: "Indicateurs",

//       exponential_moving_average: "Moyenne mobile exponentielle",
//       volume_weighted_average_price: "Prix ​​moyen pondéré en volume",
//       simple_moving_average: "Moyenne mobile simple",
//       moving_average_convergence_divergence: "Divergence moyenne mobile de convergence",
//       bollinger_bands: "Bandes de Bollinger",
//       relative_strength_index: "indice de force relative",
//       average_true_range: "Moyenne mobile véritable",
//       rate_of_change: "Taux de changement",
//       weighted_moving_average: "Moyenne mobile pondérée",
//       commodity_channel_index: "Indicateur des canaux de marchandises",
//       momentum: "Élan",

//       period: "Période",
//       sma: "MMS",
//       ema: "MME",
//       vwap: "CMPV",

//       base_on: "Base sur",
//       color: "Couleur",
//       standard_deviation: "Déviation Standard",
//       short_period: "courte période",
//       long_period: "Longue Période",

//       loading_dataset: "Chargement du jeu de données: ",
//       save_chart: 'Sauvegarder',
//     },
//     screener: {
//       "nodata": "PAS DE DONNÉES DISPONIBLES",
//       searchforcriteria: 'Rechercher des critères',
//       presetscreener: "Écrans pré-établis", //
//       critera: "Critères",
//       addfilter: "Ajouter un filtre",
//       addfilters: "Ajouter un filtres",
//       marketscreener: "Testeur de marché",
//       nocritera: "Vous n'avez encore choisi aucun critère. Veuillez sélectionner un critère pour commencer",
//       noresults: "Aucun résultat trouvé pour les critères sélectionnés",
//       save: 'Sauvegarder',
//       savedscreens: 'Écrans Sauvegardé',
//       nosaved: "Vous n'avez aucun écran enregistré",
//       entername: "Entrez le nom de l'écran",
//       or: 'ou',
//       updateexisting: 'Mettre à jour les écrans sortants',
//       showcritera: 'Afficher les critères',
//       hidecritera: 'Masquer les critères',

//       customCols: "Gérer les vues",
//       entercol: 'Entrez le nom de la nouvelle vue',
//       modify_existing: "Modifier l'existant",
//       "saved": "Enregistré",
//       "new": "Nouveau"
//     },
//     tabs: {
//       overview: 'Aperçu',
//       basics: "bases",
//       ratios: "proportion",
//       performance: "résultats",
//       dividends: "dividendes",
//       profitability: "rentabilité",
//       trading: "statistiques",
//       financials:"finances",
//       export: "exportation",
//       manage: "gérer"
//     },
//     tableheaders: {
//       symbol: "Symbole",
//       name: "nom",
//       last: "Dern.",
//       change: "Var",
//       changepct: "Var %",
//       open: "Ouverture",
//       high: "Haut",
//       low: "Min",
//       bid: "Acheteur",
//       ask: "Vendeur",
//       vwap: "CMPV",
//       volume: "Volume",

//       avgCreditQuality: "qualité du crédit",
//       inverseFund: "fonds inversé",
//       leveragedFund: "fonds à levier",

//       divyeild: "Rendement (%)",
//       beta: "Bêta",
//       mktcap: "Valeur à la cote",
//       sharesout: "Actions en circ.",
//       eps: "BPA",
//       peratio: "Ratio C/E",
//       pbratio: "Ratio P/V",
//       high_vwap: "Max. 52 sem. CMPV",
//       low_vwap: "Min. 52 sem. CMPV",
//       wkh52: "Haut sur 52 semaines",
//       wklw52: "Bas sur 52 semaines",
//       high_vol: "Max. 52sem. Vol",
//       low_vol: "Min. 52 sem. Vol",
//       avg_vol: "52 sem. Ava Vol",

//       marketCapitalization: "Valeur à la cote",

//       Exchange: "Bourse",
//       Sector: "Secteur",
//       Industry: "Industrie",
//       revenueLtm: "Rev. (LTM)",
//       freeCashFlow: "Flux de trésorerie disponible",
//       employees: "Des employés",
//       totalRevenue: "Total des produits",
//       stockPrice: "Prix",

//       latestFiscalEps: "BPA",
//       pbRatio: "Ratio P/V",
//       peRatio: "Ratio C/E",
//       currentRatio: "Ratio actuel",
//       quickRatio: "Ratio Rapide",
//       debtCapitalRatio: "ratio d'endettement",
//       bvps: "BVPS",
//       pcfRatio: "Ratio %",
//       debtToEquityRatio: "ratio capitaux propres",

//       priceChange: "Variation du cours",
//       change52w: "Variation sur 52 semaines",
//       pricePerfomance7d: "Perf. 7j",
//       pricePerfomance30d: "Perf. 30j",
//       pricePerfomance90d: "Perf. 90j",
//       pricePerfomanceYtd: "Perf. AJP",
//       incomeGrowth3yr: "croissance des revenus 3ans",
//       incomeGrowth5yr: "croissance des revenus 5ans",
//       revenueGrowth3yr: "croissance du chiffre d'affaires 3ans",
//       revenueGrowth5yr: "croissance du chiffre d'affaires 5ans",

//       divYield: "Rendement de dividende",
//       divRate: "Taux de dividende",
//       divGrowthAvg3y: "Croissance des rev. 3 ans",
//       divGrowthAvg5y: "Croissance des rev. 5 ans",

//       roa: "RSA",
//       roe: "RDCP",
//       roc: "RDC",
//       grossMargin: "Marge brute",
//       ebitdaMargin: "Marge EBITDA",
//       ebitMargin: "Marge EBIT",
//       revPs: "rev. Pa",

//       r2: "r2",
//       alpha: "alpha",
//       beta: "Bêta",
//       stddev: "std Dév.",
//       instHoldingPct: "institution détenue %",
//       insiderHoldingPct: "% de détention d'initiés",
//       yesterdaysVolume: "hier Vol.",
//       averageVolume10d: "Vol. moy. 10d",
//       averageVolume30d: "Vol. moy. 30d",
//       averageVolume50d: "Vol. moy. 50d",
//       averageVolume90d: "Vol. moy. 90d",

//       currentLiabilities: "Passifs courants",
//       totalEquity: "Total des capitaux propres",
//       bookValue: "valeur comptable",
//       currentAssets: "Actifs courants",
//       totalAssets: "Actif total",
//     },
//     msgs: {
//       min_to_low: 'La valeur saisie est inférieure au minimum. Affichage du minimum.',
//       max_to_low: 'La valeur saisie est supérieure au maximum. Affichage du maximum.',
//       to_get_started_type_in_the_input_above: "Pour commencer, tapez l'entrée ci-dessus.",
//     },
//     buttons: {
//       close: "Fermer",
//       add_symbol: "Ajouter un symbole",
//       indicators: "Indicateurs",
//     },
//   },
//   jp: {
//     msgs: {
//       to_get_started_type_in_the_input_above: "上記の欄に銘柄名（英語)もしくは証券コードを入力してください",
//     },
//     buttons: {
//       close: "閉じる",
//       indicators: "指標を追加",
//       add_symbol: "コードを追加",
//     },
//     labels: {
//       ohlcmsg: "このインディケーターでは、チャートタイプがローソク足またはOHLCのいずれかである必要があります",
//       overlay: 'オーバーレイインディケーター',
//       lower: 'ローワーインディケーター',
//       active: 'アクティブインディケーター',
//       open: "始値",
//       high: "高値",
//       low: "安値",
//       last: "終値",
//       frequency: "頻度",
//       events: "イベント",
//       scale: "目盛",
//       type: "チャートタイプ",
//       "1D": "1日",
//       "5D": "5日",
//       "3M": "3カ月",
//       "6M": "6カ月",
//       "1Y": "1年",
//       "2Y": "2年",
//       "3Y": "3年",
//       "5Y": "5年",
//       "10Y": "10年",
//       "20Y": "20年",
//       "30Y": "30年",
//       "1min": "1分",
//       "2min": "2分",
//       "5min": "5分",
//       "10min": "10分",
//       "60min": "60分",
//       "YTD": "YTD",
//       daily: "日次",
//       weekly: "週次",
//       monthly: "月次",
//       adjustments: "調整",
//       splits_and_divs: "株式分割・配当",
//       splits: "分割",
//       unadjusted: "調整前",
//       price: "株価",
//       change_dollar: "前日比($)",
//       change_percent: "前日比(%)",
//       period: "期間",
//       sma: "SMA",
//       ema: "EMA",
//       vwap: "VWAP",
//       earnings: "決算発表",
//       dividends: "配当",
//       area: "エリア",
//       line: "ライン",
//       "OHLC+": "OHLC+",
//       OHLC: "OHLC",
//       "Candlestick+": "ローソク足＋",
//       Candlestick: "ローソク足",
//       symbol_lookup: "コード検索",
//       symbol_or_name: "コード・銘柄名",
//       search_by: "検索",
//       close: "終値",
//       name: "銘柄名",
//       symbol: "コード",
//       add_indicator: "指標を追加",
//       indicators: "指標を追加",
//       exponential_moving_average: "指数平滑移動平均線",
//       volume_weighted_average_price: "VWAP（出来高加重平均価格)",
//       simple_moving_average: "単純移動平均線",
//       moving_average_convergence_divergence: "MACD",

//       pivotpoints: 'ピボットポイント',
//       priceenvelopes : 'プライス　エンベローペ',
//       parabolic_sar: 'パラボリックSAR',
//       volume_by_price: '価格別出来高',
//       zigzag: 'ジグザグ',
//       detrended_price: 'トレンド除去価格オシレーター',

//       bollinger_bands: "ボリンジャーバンド",
//       relative_strength_index: "RSI(相対力指数)",
//       average_true_range: "ATR(アベレージ・トゥルー・レンジ)",
//       rate_of_change: "ROC(レイトオブチェンジ)",
//       weighted_moving_average: "加重移動平均",
//       commodity_channel_index: "商品チャンネル指数",
//       momentum: "モメンタム",
//       base_on: "基準",
//       color: "色",
//       standard_deviation: "標準偏差",
//       short_period: "短期間",
//       long_period: "長期間",
//       loading_dataset: "ロード中: ",
//       add: '追加',
//       remove: '削除',
//       ranges: '範囲',
//       increment: '増加',
//       Initial_Acceleration_Factor: '初期加速係数',
//       Max_Acceleration_Factor: '最大加速係数',
//       Deviation: '偏差',
//       deviation: '偏差',
//       High_Index: '高インデックス',
//       Low_Index: '低インデックス',
//       Bottom_Band: 'ボトムバンド',
//       Top_Band: 'トップバンド',

//       highIndex: '高インデックス',
//       lowIndex: '低インデックス',
//       bottomBand: 'ボトムバンド',
//       topBand: 'トップバンド',

//       index: 'インデックス',
//       shortPeriod: "短期間",
//       longPeriod: "長期間",
//       signalPeriod: "シグナル期間",
//       signal_period: "シグナル期間",
//     }

//   }
// };

import en from './en.json'
import fr from './fr.json'
import jp from './jp.json'

export default {
  en,
  fr,
  jp
};
