/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => ({
    ENV: null,
    SID: undefined,
    WMID: undefined,
    IS_LOGGEDIN: false,
    METRICS: null,
  }),
  mutations: {
    SET_SID(state, sid) {
      if (sid)
        state.SID = sid;
    },
    SET_ENV(state, env) {
      state.ENV = env;
    },
    SET_WMID(state, wmid) {
      state.WMID = wmid;
    },
    SET_IS_LOGGEDIN(state, value) {
      state.IS_LOGGEDIN = value;
    },
    ADD_METRICS(state, metrics) {
        state.metrics = metrics;
    }
  },
  actions: {
    async validateSID({
      state,
      commit,
    }) {
      try {
        const results = await axios.get(
          `//${state.ENV}.quotemedia.com/auth/v0/session/${
            state.SID ? "?sid=" + state.SID : ""
          }`,
          {
            withCredentials: true,
          }
        );
        commit('SET_SID', results.data.sid);
        commit('SET_IS_LOGGEDIN', true);
      } catch (e) {
        commit('SET_IS_LOGGEDIN', false);
      }
    }
  }
})
