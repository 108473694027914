<template lang="pug">
    <v-app :id="c_qmComponentName" class='qmodii-app' :dark="c_qmComponentProps.dark">
      <v-content class='qmodii-content'>
        <component :is="c_qmComponentName" :qmWMID="c_qmWMID" v-bind="c_qmComponentProps"></component>
      </v-content>
    </v-app>
</template>
  
<script>
    /* eslint-disable */
    import { VContent } from "vuetify/lib";
    // import FullChart from "@/components/full/fullchart/FullChart.vue";
    // import AdvancedChart from "@/components/full/advancedchart/AdvancedChart.vue";
    // import MarketScreener from "@/components/full/marketscreener/MarketScreener.vue";
    // import PortfolioTransactional from "@/components/full/portfoliotransactional/PortfolioTransactional.vue";

    export default {
        name: "qModii",
        props: [
            "qmWMID",
            "qmDOMid",
            "qmComponentName",
            "qmComponentProps",
            "qmEnv",
            "qmSID",
        ],
        computed: {
            c_qmComponentName() {
            return this.qmComponentName;
            },
            c_qmComponentProps() {
            return this.qmComponentProps;
            },
            c_qmWMID() {
            return this.qmWMID;
            },
        },
        components: {
            VContent,
            MarketScreener: () =>
            import(
                /* webpackChunkName: "c_marketscreener" */ "@/components/full/marketscreener/MarketScreener.vue"
            ),
            // AdvancedChart
            AdvChart: () =>
            import(
                /* webpackChunkName: "c_advancedchart" */ "@/components/full/advancedchart/AdvancedChart.vue"
            ),
        },
        mounted() {
            this.$store.commit("SET_SID", this.qmSID);
            this.$store.commit("SET_ENV", this.qmEnv);
            this.$store.commit("SET_WMID", this.qmWMID);
            // console.log(this.initTool)
            // if (this.initTool) {
            //   this.switchTool(this.initTool)
            // }
            // let id = this.$el.id
            // console.log(id + " ::Loaded Init on " + this.initTool)
            // qMod.eventBus.$on(id + ":loadTool ", qmodToolName => {
            //   console.log(id + " ::Loaded on " + qmodToolName)
            //   this.switchTool(qmodToolName)
            // })
        },
        data() {
            return {
            // options: null,
            // currentComponent: "AdvChart"
            //
            };
        },
};
</script>
  
<style lang="stylus">
  @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900')
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons')
  
  .qmodii
    box-sizing border-box
  .application  .application--wrap
    min-height auto
  .qmodii-f1
    flex 1
</style>
  