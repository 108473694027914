export default class QModPerformanceMetrics {
    constructor() {
        this.list = {};
        this.global = [];
        this.qmodEventData = window.qmodEventData = window.qmodEventData || [];
        this.sentBeacon = false;
        this.initTime = Date.now();
        this.initialize();
    }

    initialize() {
        if ("onpagehide" in self) {
            addEventListener("pagehide", this.sendBeacon.bind(this), { capture: true });
        } else {
            // only register beforeunload/unload in browsers that don't support
            // pagehide to avoid breaking bfcache
            addEventListener("unload", this.sendBeacon.bind(this), { capture: true });
            addEventListener("beforeunload", this.sendBeacon.bind(this), { capture: true });
        }

        // visibilitychange may be your last opportunity to beacon,
        // though the user could come back later
        addEventListener(
            "visibilitychange",
            () => {
                if (document.visibilityState === "hidden") {
                    this.sendBeacon();
                }
            },
            { capture: true }
        );

        // send data at load!
        addEventListener(
            "load",
            () => {
                // this.qmodEventData.push({
                //     ts: Date.now(),
                //     type: "onload",
                // });
                // console.log("onload event", this.qmodEventData);
            },
            { capture: true }
        );
    }

    add(tool, tracktype, props, wmid) {
        let toolTrack = tool.id ? tool.id : tool;
        if (!this.list[toolTrack]) {
            this.list[toolTrack] = [];
            this.list[toolTrack].push({ track: "start", ts: Date.now() });
        }

        let lastTime = this.list[toolTrack][this.list[toolTrack].length - 1];

        this.list[toolTrack].push({
            from_last: Date.now() - lastTime.ts,
            from_start: Date.now() - this.list[toolTrack][0].ts,
            ts: Date.now(),
            track: tracktype,
        });

        this.global.push({
            tool: toolTrack,
            from_last:
                Date.now() - (this.global.length > 0 ? this.global[this.global.length - 1].ts : 0),
            from_start: Date.now() - (this.global[0] ? this.global[0].ts : 0),
            ts: Date.now(),
            track: tracktype,
        });

        if (tracktype === "loaded") {
            window.qmodEventData.push({
                loadedTime: Date.now() - this.initTime,
                ts: Date.now(),
                type: "Tool_Loaded",
                tool: tool,
                toolParams: props,
                host: window.location.host,
                url: window.location.toString(),
                pathName: window.location.pathname,
                wmid: wmid,
            });

            this.qmodEventData = window.qmodEventData;
        }
    }

    sendBeacon() {
        if (!this.qmodEventData.length) {
            return;
        }

        this.sendData(this.qmodEventData);

        // 1. call navigator.sendBeacon or XHR or Image
        // 2. cleanup after yourself, e.g. handlers

        this.qmodEventData = [];
    }

    sendData(payload) {
        if (
            window &&
            window.navigator &&
            typeof window.navigator.sendBeacon === "function" &&
            typeof window.Blob === "function"
        ) {
            const blobData = new window.Blob([JSON.stringify(payload)], {
                type: "text/plain",
            });

            try {
                if (
                    window.navigator.sendBeacon(
                        "https://influxdb.quotemedia.com/telegraf",
                        blobData
                    )
                ) {
                    // sendBeacon was successful!
                    return;
                }
            } catch (e) {
                // fallback below
            }
        }

        // Fallback to XHR or Image
        // this.sendXhrOrImageBeacon();
    }

    // Optionally, you can implement a fallback method for XHR or Image
    // sendXhrOrImageBeacon() {
    //     // Implement fallback logic here
    // }
}
